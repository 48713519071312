import React, { useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [textTransitionComplete, setTextTransitionComplete] = useState(false);
  const headerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
          setTextTransitionComplete(false);
        }
      },
      { threshold: 0.5 }
    );

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observer.unobserve(headerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setTextTransitionComplete(true);
      }, 800);
    }
  }, [isVisible]);

  useEffect(() => {
    if (textTransitionComplete && imageRef.current) {
      imageRef.current.style.transition = "box-shadow 0.3s ease-in-out";
      imageRef.current.style.boxShadow = "0px 0px 50px 30px #ffffff";
    }
  }, [textTransitionComplete]);

  return (
    <header
      id="header"
      ref={headerRef}
      className="relative w-full h-screen flex items-center bg-gradient-to-b from-white to-grey-200 md:bg-none"
    >
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-center h-full px-4">
      <div className="md:w-1/2 text-center md:text-left">
          <Transition
            show={isVisible}
            enter="transition-opacity transform duration-1000"
            enterFrom="opacity-0 translate-x-[-100%]"
            enterTo="opacity-100 translate-x-0"
            leave="transition-opacity transform duration-1000"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-[-100%]"
            className="delay-200"
          >
            {(ref) => (
              <h1
                ref={ref}
                className="text-4xl md:text-6xl font-bold transition-transform duration-1000 transform translate-x-0 text-shadow name"
                style={{
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                  marginTop: "9%",
                }}
              >
                ABHISHEK JANGA
              </h1>
            )}
          </Transition>
          <Transition
            show={isVisible}
            enter="transition-opacity transform duration-1000"
            enterFrom="opacity-0 translate-x-[-300%]"
            enterTo="opacity-100 translate-x-0"
            leave="transition-opacity transform duration-1000"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-[-300%]"
            className="mt-4 delay-400"
          >
            {(ref) => (
              <p
                ref={ref}
                className="text-xl md:text-2xl mb-4 transition-transform duration-1000 transform translate-x-0 text-shadow"
                style={{
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                }}
              >
                Web Developer | Freelancer | Skilled in React, Node.js, PHP, and JavaScript | Passionate About Creating Accessible and Scalable Solutions | MSc in Advanced Computer Science
              </p>
            )}
          </Transition>
          <Transition
            show={isVisible}
            enter="transition-opacity transform duration-1000"
            enterFrom="opacity-0 translate-x-[-600%]"
            enterTo="opacity-100 translate-x-0"
            leave="transition-opacity transform duration-1000"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-[-600%]"
            className="mt-4 delay-600"
          >
            {(ref) => (
              <p
                ref={ref}
                className="text-lg md:text-xl transition-transform duration-1000 transform translate-x-0"
                style={{
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                  marginBottom: "5%",
                }}
              >
                
              </p>
            )}
          </Transition>
          <div className="w-full">
            <Transition
              show={isVisible}
              enter="transition-opacity transform duration-1000 delay-200"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="transition-opacity transform duration-1000"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              {(ref) => (
                <div
                  ref={ref}
        className="flex flex-col xl:flex-row max-sm:items-center xl:items-start md:leading-loose xl:leading-none xl:space-x-6 mt-4"
                >
                  <a
                    href="https://www.linkedin.com/in/abhishek-janga/"
                    className="flex items-center hover:underline transition duration-300 ease-in-out mb-4 md:mb-0"
                  >
                    <img
                      src="/images/LinkedIn.png"
                      alt="LinkedIn"
                      className="w-6 h-6 mr-2"
                    />
                    LinkedIn
                  </a>
                  <a
                    href="https://github.com/Janga-Abhishek"
                    className="flex items-center hover:underline transition duration-300 ease-in-out mb-4 md:mb-0"
                  >
                    <img
                      src="/images/Github.png"
                      alt="GitHub"
                      className="w-6 h-6 mr-2"
                    />
                    GitHub
                  </a>
                  <span className="flex items-center">
                    <img
                      src="/images/Gmail.png"
                      alt="Gmail"
                      className="w-10 h-6 mr-2"
                    />
                    janga.abhishek@gmail.com
                  </span>
                  <div className="flex flex-col items-start mt-4 md:mt-0">
 
  <span className="flex items-center">
    <img
      src="/images/india.png"
      alt="Phone"
      className="w-6 h-6 mr-2"
    />
    +91 9100585090
  </span>
</div>
                </div>
              )}
            </Transition>
          </div>

          {/* Add resume button here */}
          <Transition
            show={isVisible}
            enter="transition-opacity transform duration-1000 delay-800"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition-opacity transform duration-1000"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
            className="mt-12"
          >
            {(ref) => (
              <div ref={ref}>
                <a
                  className="resume"
                  href="/images/Abhishek Janga.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    border: "2px solid black",
                    padding: "8px 16px",
                    backgroundColor: "white",
                    fontWeight: "600",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 1.2)",
                    transition: "box-shadow 0.3s ease",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    maxWidth: "fit-content",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.boxShadow =
                      "0 8px 16px rgba(0, 0, 0, 0.4)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.boxShadow =
                      "0 4px 8px rgba(0, 0, 0, 1.2)")
                  }
                >
                  <img
                    src="/images/resumeicon.png"
                    alt="Resume Icon"
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  View My Resume
                </a>
                <style jsx>{`
                  @media (max-width: 768px) {
                    .resume {
                      margin: 0 auto; /* Center the button on small screens */
                    }
                  }
                `}</style>
              </div>
            )}
          </Transition>
        </div>
      <div className="md:w-1/2 relative flex justify-center mt-8 md:mt-0">
          <Transition
            show={isVisible}
            enter="transition-opacity transform duration-1000"
            enterFrom="opacity-0 translate-x-[100%]"
            enterTo="opacity-100 translate-x-0"
            leave="transition-opacity transform duration-1000"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-[100%]"
            className="delay-800"
          >
            {(ref) => (
              <div
                ref={ref}
                className="relative w-48 h-48 md:w-80 md:h-80 lg:w-96 lg:h-96 rounded-[50px] shadow-rainbow janga_image"
                style={{
                  border: "2px solid white",
                }}
              >
                <div
                  ref={imageRef}
                  className="absolute inset-0 w-full h-full rounded-[50px] overflow-hidden flex items-center justify-center"
                >
                  <img
                    src="/images/Abhishek_Janga.jpg"
                    alt="Abhishek Janga"
                    className="object-cover w-full h-full rounded-[50px] transition-transform duration-1000 transform"
                  />
                </div>
              </div>
            )}
          </Transition>
        </div>
 
        
      </div>
    </header>
  );
};

export default Header;
